import { BaseElement, html, css } from 'Elements';
import { Session } from 'Utils';

import styles from './styles.js';

class Block extends BaseElement {
  static get styles() {
    return [
      styles,
      css`
        .img_icons {
          height:18px;
        }
      `
    ];
  }

  render() {
    if (!this.parent.ticket) return;
    return html`
      <div class="margin">
        <section-header size="medium">
          <img slot="left" src="assets/img/services.png" class="img_icons"/>
          <a id="infos_declarant">Informations du déclarant</a>
        </section-header>
        <br/>
        <div class="line">
          <div>Organisme d'affiliation :</div>
          <div class="bold">${this.parent.ticket.affiliate}</div>
        </div>
        <div class="line">
          <div>Numéro d'adhérent :</div>
          <div class="bold">${this.parent.ticket.clientId || 'N/A'}</div>
        </div>
        <div class="line">
          <div>Société :</div>
          <div class="bold">${this.parent.ticket.company}</div>
        </div>
        <div class="line">
          <div>Nom / Prénom :</div>
          <div class="bold">${this.parent.ticket.declarant}</div>
        </div>
        <div class="line">
          <div>Adresse email :</div>
          <div class="bold">${this.parent.ticket.declarantEmail}</div>
        </div>
        <div class="line">
          <div>Numéro de téléphone :</div>
          <div class="bold">${this.parent.ticket.declarantPhone}</div>
        </div>
      </div>
    `;
  }

}

customElements.define('incident-block-infos-declarant', Block);