import { BaseElement, html, css } from 'Elements';
import { Session } from 'Utils';

import styles from './styles.js';

class Block extends BaseElement {
  static get styles() {
    return [
      styles,
      css`
        .img_icons {
          height:18px;
        }
      `
    ];
  }

  static get properties() {
    return {
      parent: { type: Object }
    };
  }

  render() {
    if (!this.parent.ticket) return;

    return html`
      <div class="margin">
        <section-header size="medium">
          <img slot="left" src="assets/img/evaluation.png" class="img_icons"/>
          ${this.parent.isIncident() ? 'Evaluation de l\'incident' : 'Evaluation de l\'alerte'}
          ${Session.isSoc() 
            ? html`<div slot="right"><m-icon name="add" nogradient title="Ajouter une contre mesure" @click="${this.counterMeasureAdd}"></m-icon></div>`
            : ''
          }
        </section-header>
        <br/>
        <div style="display:flex;justify-content:space-between;margin-left:10px;">
          <div style="display:flex;flex-direction:column;">
            <div class="line2"><div>Sévérité :</div><div class="bold">${this.parent.ticket.severityText}</div></div>
            <div class="line2"><div>Classification :</div><div class="bold">${this.parent.ticket.classificationText}</div></div>
            <div class="line2"><div>Sous Classification :</div><div class="bold">${this.parent.ticket.subClassificationText}</div></div>
          </div>
        </div>
      </div>
    `;
  }

}

customElements.define('incident-block-evaluation', Block);