import { BaseElement, html, css, unsafeHTML } from 'Elements';
import { Session } from 'Utils';

import styles from './styles.js';

class Block extends BaseElement {
  static get styles() {
    return [
      styles,
      css`
        .img_icons {
          height:22px;
        }

        .text {
          margin-left: 10px;
        }
      `
    ];
  }

  static get properties() {
    return {
      parent: { type: Object }
    };
  }

  render() {
    if (!this.parent.ticket) return;

    return html`
      <div class="margin">
        <section-header size="medium">
          <img slot="left" src="assets/img/resultat.png" class="img_icons"/>
          Analyse(s) et résultat(s) effectué(s) par le CSIRT SysDream
        </section-header>
        <div class="margin text">${unsafeHTML(this.parent.ticket.analyseResultText.join('<br/>'))}</div>
      </div>
    `;
  }

}

customElements.define('incident-block-resultats', Block);