import { BaseElement, html, css } from 'Elements';
import { Session, Fetcher, Notify } from 'Utils';
import App from 'App';

import styles from './styles.js';

class Block extends BaseElement {
  static get styles() {
    return [
      styles,
      css`

        section-header.disable {
          filter: grayscale(100%);
          opacity: 0.7;
        }

        .img_icons {
          height:24px;
        }

        m-icon[name="add"] {
          font-size:30px;
          cursor:pointer;
        }

        .text {
          margin-left: 10px;
        }

        sl-tab-group {
          min-width: 40vw;
        }

        sl-tab-panel {
          padding:10px;
        }

        sl-switch {
          font-size: 14px;
        }

        .cm_number {
          --label-width: 200px;
          --gap-width: 1rem;
        }

        .cm_number + .cm_number {
          margin-top: var(--sl-spacing-medium);
        }

        .cm_number::part(form-control) {
          display: grid;
          grid: auto / var(--label-width) 1fr;
          gap: var(--sl-spacing-3x-small) var(--gap-width);
          align-items: center;
        }

        .cm_number::part(form-control-label) {
          text-align: left;
        }

        .cm_number::part(form-control-help-text) {
          grid-column-start: 2;
        }

        .cm_number::part(form-control-input) {
          width:70px;
        }

        .countermeasures {
          padding-left:10px;
          padding-right:30px;
        }

        .countermeasures .line {
          display: grid;
          grid-template-columns: 20px 1fr 1fr 40px;
        }

        .countermeasures m-icon[name="more_vert"] {
          cursor:pointer;
        }

        .countermeasures .line div {
          display:flex;
          line-height:30px;
        }

        .countermeasures .line div:nth-child(4) {
          text-align:right;
        }

        .countermeasures .line.head {
          border-bottom:var(--sl-color-neutral-300) 1px solid;
          font-weight:bold;
          margin-bottom:3px;
        }

        @media print {
          m-icon[name="add"] {
            display:none;
        }
      `
    ];
  }

  static get properties() {
    return {
      parent: { type: Object }
    };
  }

  constructor() {
    super();

    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.templates = {
      cipher: {
        french:{
          title:'Préférez une solution chiffrée',
          help:''
        },
        english:{
          title:'Prefer an encrypted solution',
          help:''
        }
      },
      local_account: {
        french:{
          title:'Ne pas utiliser de comptes locaux',
          help:''
        },
        english:{
          title:'Do not use local accounts',
          help:''
        }
      },
      uninstall:{
        french:{
          title:'Désinstallation du logiciel/binaire',
          help:''
        },
        english:{
          title:'Uninstall the software/binary',
          help:''
        }
      },
      user_awareness:{
        french:{
          title:'Sensibilisation de l\'utilisateur',
          help:''
        },
        english:{
          title:'User awareness',
          help:''
        }
      },
      blacklist:{
        french:{
          title:'Ajout de(s) IP(s) en blacklist',
          help:''
        },
        english:{
          title:'Add IP(s) to blacklist',
          help:''
        }
      },
      isolate:{
        french:{
          title:'Isoler les actifs concernés',
          help:''
        },
        english:{
          title:'Isolate the affected assets',
          help:''
        }
      }
    }
  }

  firstUpdated() {
    super.firstUpdated();
    this.modalEdit = this.shadowRoot.querySelector('#modal-incident-cm-edit');
  }

  handleSelectChange(event) {

    const selectedTemplate = this.templates[event.target.value];
    const inputElementFr = this.modalEdit.querySelector('sl-input[name="cm-fr"]');
    const inputElementEn = this.modalEdit.querySelector('sl-input[name="cm-en"]');

    inputElementFr.value = selectedTemplate.french.title;
    inputElementEn.value = selectedTemplate.english.title;

    this.modalEdit.querySelector('sl-select[name="pcm-fr"]').value = event.target.value;
    this.modalEdit.querySelector('sl-select[name="pcm-en"]').value = event.target.value;
  }

  counterMeasureAdd() {
    if (!this.parent.isIncident()) {
      const modal = this.shadowRoot.querySelector('#modal-incident-cm-warning');
      modal.show();
      return;
    }

    this.modalEdit.label = 'Ajouter une contre mesure';
    this.modalEdit.querySelector('sl-input[name="pos-fr"]').value = this.parent.getCounterMeasuresCount();
    this.modalEdit.querySelector('sl-input[name="pos-en"]').value = this.parent.getCounterMeasuresCount();
    this.modalEdit.show();
  }

  async handleSubmit() {

    const payload = {
      title:{
        english: this.modalEdit.querySelector('sl-input[name="cm-en"]').value,
        french: this.modalEdit.querySelector('sl-input[name="cm-fr"]').value
      },
      help:{
        english: this.modalEdit.querySelector('sl-textarea[name="cm-en"]').value,
        french: this.modalEdit.querySelector('sl-textarea[name="cm-fr"]').value
      },
      cmid: this.modalEdit.querySelector('sl-select[name="pcm-fr"]').value,
      position: parseInt(this.modalEdit.querySelector('sl-input[name="pos-fr"]').value),
      customerResponseRequired: this.modalEdit.querySelector('sl-switch[name="cr-fr"]').checked
    }
    
    if (!payload.title.french) {
      Notify.error('Le titre de la contre mesure est obligatoire');
      return;
    }

    this.modalEdit.loadingButtons();

    const url = `private/soc/alert/${this.parent.hash}/counter-measures`;
    const response = await Fetcher.post(url, payload);
    if (!response || !response.data) {
      this.modalEdit.resetButtons();
      return;
    }

    this.modalEdit.resetButtons();
    this.modalEdit.hide();
    await this.parent.reloadTicket();
    this.parent.requestUpdate();
    this.parent.initIntersectionObserver();
  }

  changePosition(ev) {
    const value = ev.target.value;
    this.modalEdit.querySelector('sl-input[name="pos-en"]').value = value;
    this.modalEdit.querySelector('sl-input[name="pos-fr"]').value = value;
  }

  changeCustomerResponseRequired(ev) {
    const checked = ev.target.checked;
    this.modalEdit.querySelector('sl-switch[name="cr-en').checked = checked;
    this.modalEdit.querySelector('sl-switch[name="cr-fr').checked = checked;
  }

  formatCounterMeasures() {
    if (!this.parent.ticket.countermeasures || !this.parent.ticket.countermeasures.length) return 'Aucune contre-mesure n\'a été proposé';

    let txt = html``;

    this.parent.ticket.countermeasures.forEach(cm => {

      //const help = cm.help && cm.help[App.config.language];

      let customerResponseRequired;
      if (cm.customerResponseRequired) {
        if (cm.customerResponseDate) {
          customerResponseRequired = this.parent.formatDate(cm.customerResponseDate);
        } else {
          customerResponseRequired = 'En attente';
        }
      } else {
        customerResponseRequired = 'N/A';
      }

      txt = html`
        ${txt}
        <div class="line">
          <div>${cm.position}.</div>
          <div>${cm.title && cm.title[App.config.language]}</div>
          <div>${customerResponseRequired}</div>
          <div>
            <sl-dropdown>
              <m-icon slot="trigger" name="more_vert" nogradient></m-icon>
              <sl-menu>
                <sl-menu-item @click="${this.counterMeasureEdit}">Modifier</sl-menu-item>
                <sl-menu-item .cm=${cm} @click="${this.counterMeasureDeleteConfirm}">Supprimer</sl-menu-item>
              </sl-menu>
            </sl-dropdown>
          </div>
        </div>
      `;
    });

    txt = html`
      <div class="countermeasures">
        <div class="line head">
          <div>#</div>
          <div>Proposée</div>
          <div>Appliquée</div>
          <div>&nbsp;</div>
        </div>
        ${txt}
      </div>
    `;

    return txt;
  }

  counterMeasureDeleteConfirm(ev) {
    this.modalDelete = this.shadowRoot.querySelector('#modal-incident-cm-del');
    this.modalDelete.show();
    this.cmToDelete = ev.target.cm;
  }

  async counterMeasureDelete() {
    const payload = {
      id: this.cmToDelete._id
    }
    
    const url = `private/soc/alert/${this.parent.hash}/counter-measures`;
    const response = await Fetcher.delete(url, payload);
    this.cmToDelete = null;

    if (!response || !response.data) {
      this.modalDelete.hide();
      return;
    }

    this.modalDelete.hide();
    await this.parent.reloadTicket();
    this.parent.initIntersectionObserver();
  }

  counterMeasureEdit() {
    if (!this.parent.isIncident()) {
      const modal = this.shadowRoot.querySelector('#modal-incident-cm-warning');
      modal.show();
      return;
    }

    this.modalEdit.label = 'Modifier une contre mesure';
    this.modalEdit.querySelector('sl-input[name="pos-fr"]').value = this.parent.getCounterMeasuresCount();
    this.modalEdit.querySelector('sl-input[name="pos-en"]').value = this.parent.getCounterMeasuresCount();
    this.modalEdit.show();
  }
  
  render() {
    if (!this.parent.ticket) return;

    return html`
      ${Session.isSoc()
        ? html`<div class="margin">
          <section-header class="${this.parent.isIncident() ? '' : 'disable'}" size="medium">
            <img slot="left" src="assets/img/contremesure.png" class="img_icons"/>
            Contre-mesures pour le déclarant
            ${Session.isSoc() 
              ? html`<div slot="right"><m-icon name="add" nogradient title="Ajouter une contre mesure" @click="${this.counterMeasureAdd}"></m-icon></div>`
              : ''
            }
          </section-header>
          <div class="margin text">
            <sl-tab-group>
              <sl-tab slot="nav" panel="soc">Vue SOC</sl-tab>
              <sl-tab slot="nav" panel="client">Vue client</sl-tab>
              <sl-tab-panel name="soc">
                ${this.formatCounterMeasures()}
              </sl-tab-panel>
          </div>
        </div>

        <modal-dialog label="Attention" id="modal-incident-cm-warning">
          <style>${this.constructor.styles.toString()}</style>
          Vous ne pouvez pas ajouter de contre-mesures à ce stade (alerte).<br/><br/>Vous devez d'abord escalader l'alerte en incident.
          <sl-button slot="bt1" variant="text" close="true">Fermer</sl-button>
          <sl-button slot="bt2" variant="danger">Escalader l\'alerte en incident</sl-button>
        </modal-dialog>

        <modal-drawer label="" id="modal-incident-cm-edit">
          <style>${this.constructor.styles.toString()}</style>
          <form>
          <sl-tab-group>
            <sl-tab slot="nav" panel="french">🇫🇷 Français</sl-tab>
            <sl-tab slot="nav" panel="english">🇺🇸 Anglais</sl-tab>

            <sl-tab-panel name="french">
              <sl-select name="pcm-fr" label="Modèles prédéfinis" size="small">
                <sl-option>Aucun</sl-option>
                ${Object.keys(this.templates).map((key) => {
                  const template = this.templates[key];
                  return html`<sl-option @click="${this.handleSelectChange}" size="small" value="${key}">${template.french.title}</sl-option>`;
                })}
              </sl-select><br/>
              <sl-input name="cm-fr" label="Intitulé de la contre mesure" required  size="small"></sl-input><br/>
              <sl-textarea name="cm-fr" label="Explications pour le client (optionnel)"  size="small"></sl-textarea><br/>
              <sl-input name="pos-fr" class="cm_number" label="Numéro de la contre mesure" type="number" min="0" required @sl-change="${this.changePosition}" size="small"></sl-input><br/>
              <sl-switch name="cr-fr" size="small" @sl-change="${this.changeCustomerResponseRequired}">Réponse exigée de la part du client / déclarant</sl-switch>
            </sl-tab-panel>

            <sl-tab-panel name="english">
              <sl-select name="pcm-en" label="Predefined templates" size="small">
                <sl-option>None</sl-option>
                ${Object.keys(this.templates).map((key) => {
                  const template = this.templates[key];
                  return html`<sl-option @click="${this.handleSelectChange}" value="${key}">${template.english.title}</sl-option>`;
                })}
              </sl-select><br/>
              <sl-input name="cm-en" label="Countermeasure Title" required size="small"></sl-input><br/>
              <sl-textarea name="cm-en" label="Explanation for the client (optional)" size="small"></sl-textarea><br/>
              <sl-input name="pos-en" class="cm_number" label="Counter Measure Number" type="number" min="0" required @sl-change="${this.changePosition}" size="small"></sl-input><br/>
              <sl-switch name="cr-en" size="small" @sl-change="${this.changeCustomerResponseRequired}">Response required from the client / declarant</sl-switch>
            </sl-tab-panel>

          </sl-tab-group>
          </form>
          <sl-button slot="bt1" variant="text" close="true">Annuler</sl-button>
          <sl-button slot="bt2" variant="primary" @click="${this.handleSubmit}">Ajouter</sl-button>
        </modal-drawer>

        <modal-dialog label="Suppression contre-mesure" id="modal-incident-cm-del">
          Etes vous sûr de vouloir supprimer cette contre-mesure ?
          <sl-button slot="bt1" variant="text" close="true">Annuler</sl-button>
          <sl-button slot="bt2" variant="primary" @click="${this.counterMeasureDelete}">Supprimer</sl-button>
        </modal-drawer>
        `
      : ''
      }
    `;
  }

}

customElements.define('incident-block-contre-mesures', Block);