import { PageElement } from 'Elements';
import App from 'App';
import utils from './utils.js';
import stepsStyles from './styles.js';

class Step extends PageElement {

  static get styles() {
    return [
      super.styles,
      stepsStyles
    ]
  }

  constructor() {
    super();
    this.loggingEnabled = false;
  }

  log(...messages) {
    if (this.loggingEnabled) {
      console.log(this.constructor.name, ...messages);
    }
  }

  async connectedCallback() {
    this.log('connectedCallback');
    super.connectedCallback();
    this.isDark = App.config.isDark;
    window.addEventListener('theme-change', this.handleThemeChange.bind(this));
    utils.handlePageBackground(this);
  }

  disconnectedCallback() {
    this.log('disconnectedCallback');
    super.disconnectedCallback();
    window.removeEventListener('theme-change', this.handleThemeChange.bind(this));
  }

  async handleThemeChange(e) {
    this.log('handleThemeChange');
    this.isDark = e.detail.isDark;
    await this.requestUpdate();
    utils.handlePageBackground();
  }
}

export default Step;