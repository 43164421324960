import { BaseElement, html, css, unsafeHTML } from 'Elements';
import { Session } from 'Utils';

import styles from './styles.js';

class Block extends BaseElement {
  static get styles() {
    return [
      styles,
      css`
        .img_icons {
          height:18px;
        }
      `
    ];
  }

  static get properties() {
    return {
      parent: { type: Object }
    };
  }

  render() {
    if (!this.parent.ticket) return;

    return html`
      <div class="margin">
        <section-header size="medium">
          <img slot="left" src="assets/img/impact.png" class="img_icons"/>
          Impact sur le service et les données du déclarant
        </section-header>
        <br/>
        <div class="line">
          <div>Impact sur des données à caractère personnel :</div>
          <div class="bold">${this.parent.ticket.impactPersonalText}</div>
        </div>
        <div class="line">
          <div>Impact sur des données de production :</div>
          <div class="bold">${this.parent.ticket.impactProdText}</div>
        </div>
        <div class="line">
          <div>Production arrêtée :</div>
          <div class="bold">${this.parent.ticket.impactProdStopText}</div>
        </div>
      </div>
    `;
  }

}

customElements.define('incident-block-impacts', Block);