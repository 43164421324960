import { BaseElement, html, css, unsafeHTML } from 'Elements';
import { Session, Notify } from 'Utils';

import styles from './styles.js';

class Block extends BaseElement {
  static get styles() {
    return [
      styles,
      css`
        :host {
          --article-background:var(--article-background-color);
          --article-delta:6px;
          --timeline-line-color:#b3d0fd;
        }
        
        .img_icons {
          height:18px;
        }

        .timeline {
          position: relative;
          margin:10px;
          padding: 20px;
          outline:1px solid #346abf22;
          overflow: auto;
          display:flex;
          align-items: flex-start;
          justify-content: space-between;
        }

        .timeline.vertical {
          position: relative;
          margin:10px;
          padding: 20px;
          outline:1px solid #346abf22;
          overflow: auto;
          display:flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-direction: column;
        }

        .timeline:after {
          content: "";
          position: absolute;
          left: 20px;
          right: 20px;
          top: 50%;
          height: 0px;
          border-top: 1px solid var(--timeline-line-color);
          border-left:0px;
          border-right:0px;
          z-index: 1;
          transform: translateY(-50%);
        }

        .timeline article {
          display: inline-block;
          position: relative;
          background: var(--article-background);
          width: auto;
          padding-top: 7px;
          padding-left:7px;
          padding-right:7px;
          padding-bottom: 5px;
          text-align:center;
          border-radius: 5px;
          cursor:pointer;
        }

        .timeline article.waiting > * {
          filter: grayscale(100%);
          opacity:0.5;
        }

        .timeline article:not(:first-child) {
          margin-left: -20px;
        }

        .timeline article:not(:last-child) {
          margin-right: -20px;
        }

        .timeline article:before {
          content: "";
          display: block;
          position: absolute;
          top: calc( 100% + var(--article-delta) );
          left: 50%;
          background: #346abf;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          transform: translateX(-50%);
          z-index: 5;
        }

        .timeline article:after {
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 10px solid var(--article-background);
          transform: translateX(-50%);
          z-index:10;
        }

        .timeline article.waiting:before {
          z-index:-1;
        }

        .timeline article:nth-child(even) {
          margin-top: 80px;
          margin-bottom: 0;
        }

        .timeline article:nth-child(even):before {
          top: auto;
          bottom: calc( 100% + var(--article-delta) );
        }

        .timeline article:nth-child(even):after {
          top: auto;
          bottom: 100%;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 10px solid var(--article-background);
          border-top: none;
        }

        .timeline article div.title {
          text-transform: initial;
          font-size: 16px;
          margin: 0;
          padding:0;
          display: inline-block;
        }

        .timeline article div.date {
          font-size: 15px;
          margin:0;
          padding:0;
        }

        m-icon[name="view_timeline"] {
          margin-right:10px;
          cursor:pointer;
        }

        .warning {
          background-color: var(--sl-color-warning-100);
          padding: 10px;
          line-height: 30px;
          margin-bottom:7px;
        }

        .warning m-icon {
          font-size: 30px;
          float: left;
          margin-right: 10px;
        }

        @media print {
          .timeline {
            background-color:white;
          }
        }
      `
    ];
  }

  static get properties() {
    return {
      parent: { type: Object }
    };
  }

  constructor() {
    super();
    this.mapEvents = {
      ALERT_CREATED:'Alerte',
      ALERT_TO_GDI: 'Alerte GDI',
      ALERT_TAKEN: 'Prise en charge',
      ALERT_TO_INCIDENT: 'Incident',
      ALERT_TO_FALSEPOSITIVE:'Faux Positif',
      ALERT_UPDATE_CM:'Contre-Mesure',
      ALERT_CLOSED: 'Clôture',
    }
  }

  keepLastEvent(history, eventName) {
    const map = {};
    for (let i = history.length - 1; i >= 0; i--) {
      const event = history[i];
      if (event.event === eventName) {
        if (!map[event.event]) {
          map[event.event] = true;
        } else {
          history.splice(i, 1);
        }
      }
    }

    return history;
  }

  keepFirstEventOnly(history, eventName) {
    const map = {};
    for (let i = 0; i < history.length; i++) {
      const event = history[i];
      if (event.event === eventName) {
        if (!map[event.event]) {
          map[event.event] = true;
        } else {
          history.splice(i, 1);
          i--; // Ajuster l'index après la suppression
        }
      }
    }

    return history;
  }

  cleanHistory(history) {
    // drop every records that are not in the mapEvents
    for (let i = history.length - 1; i >= 0; i--) {
      const event = history[i];
      if (!this.mapEvents[event.event]) {
        history.splice(i, 1);
      }
    }
  }

  drawHistory() {
    if (!this.ticket.history) return '';
    
    let history = JSON.parse(JSON.stringify(this.ticket.history));
    this.cleanHistory(history);

    if (!history.find(e => e.event === 'ALERT_TAKEN')) history.push({ event: 'ALERT_TAKEN' });
    if (!history.find(e => e.event === 'ALERT_TO_INCIDENT')) history.push({ event: 'ALERT_TO_INCIDENT' });
    if (!history.find(e => e.event === 'ALERT_UPDATE_CM')) history.push({ event: 'ALERT_UPDATE_CM' });
    if (!history.find(e => e.event === 'ALERT_CLOSED')) history.push({ event: 'ALERT_CLOSED' });

    this.keepLastEvent(history, 'ALERT_TO_FALSEPOSITIVE');
    this.keepFirstEventOnly(history, 'ALERT_UPDATE_CM');

    // si ALERT_TO_FALSEPOSITIVE est present, on supprime ALERT_TO_INCIDENT et ALERT_UPDATE_CM et ALERT_CLOSED, et on push ALERT_CLOSED avec la date de ALERT_TO_FALSEPOSITIVE
    const alertToFalsePositive = history.find(e => e.event === 'ALERT_TO_FALSEPOSITIVE');
    if (alertToFalsePositive) {
      history = history.filter(e => e.event !== 'ALERT_TO_INCIDENT' && e.event !== 'ALERT_UPDATE_CM' && e.event !== 'ALERT_CLOSED');
      history.push({ event: 'ALERT_CLOSED', date: alertToFalsePositive.date });
    }

    let str = '<div class="timeline">';
    for (const event of history) {
      str+=`
        <article class="${event.date ? '' : 'waiting'}" data-event="${event.event}">
          <div class="title">${this.mapEvents[event.event]}</div>
          <div class="date">${event.date ? this.parent.formatDate(event.date) : 'N/A'}</div>
        </article>
        `;
    }

    str+='</div>';

    return unsafeHTML(str);
  }

  drawFullHistory() {
    if (!this.ticket.history) return '';

    let str = '<div class="timeline vertical">';
    for (const event of this.ticket.history) {
      str+=`
        <article class="${event.date ? '' : 'waiting'}" data-event="${event.event}">
          <div class="title">${this.mapEvents[event.event]}</div>
          <div class="date">${event.date ? this.parent.formatDate(event.date) : 'N/A'}</div>
        </article>
        `;
    }

    str+='</div>';
    return unsafeHTML(str);
  }


  historyHelpPopup(ev) {
    // trouve l'element article au dessus de ev.target
    let article = ev.target;
    while (article.tagName !== 'ARTICLE') {
      article = article.parentElement;
      if (!article) return;
    }

    const duration = 10000;

    const event = article.getAttribute('data-event');
    if (event === 'ALERT_CREATED') Notify.info(`Date de réception de l'alerte initiale (système, ou via la plateforme)`, duration);
    if (event === 'ALERT_TO_GDI') Notify.info(`Date de déclaration de l'alerte au service Gestion des Incidents (GDI)`, duration);
    if (event === 'ALERT_TAKEN') Notify.info(`Date de prise en charge de l'alerte par un analyste`, duration);
    if (event === 'ALERT_TO_FALSEPOSITIVE') Notify.info(`Date de classification de l'alerte en incident`, duration);
    if (event === 'ALERT_TO_INCIDENT') Notify.info(`Date d'escalade de l'alerte en incident`, duration);
    if (event === 'ALERT_UPDATE_CM') Notify.info(`Date de rédaction des contre-mesures`, duration);
    if (event === 'ALERT_CLOSED') Notify.info(`Date de clôture de l'incident`, duration);
  }

  firstUpdated() {
    super.firstUpdated();
    this.modalHistory = this.shadowRoot.querySelector('#modal-incident-history-view');
  }

  updated() {
    // init a click event for every article found in the shadowRoot of the timeline
    const articles = this.shadowRoot.querySelectorAll('.timeline article');
    articles.forEach(article => {
      article.addEventListener('click', this.historyHelpPopup.bind(this));
    });
  }

  viewHistory() {
    this.modalHistory.show();
  }

  getTakenDate() {
    // boucle sur l'historique du ticket pour trouver l'evenement ALERT_TAKEN
    const event = this.ticket.history.find(e => e.event === 'ALERT_TAKEN');
    if (event) {
      return this.parent.formatDate(event.date);
    }
    return 'N/A';
  }

  render() {
    if (!this.parent.ticket) return;
    this.ticket = this.parent.ticket;

    return html`
      <div class="margin">
        <section-header size="medium">
          <img slot="left" src="assets/img/horodatage1.svg" class="img_icons"/>
          Horodatage &amp; Historique
          <div slot="right">
            <m-icon name="view_timeline" nogradient title="Afficher l'historique complet" @click="${this.viewHistory}"></m-icon>
          </div>
        </section-header>
        <br/>
        <div class="line">
          <div>Date et heure de la déclaration :</div>
          <div class="bold">${this.parent.formatDate(this.ticket.created)}</div>
        </div>
        <div class="line">
          <div>Date et heure de prise en charge :</div>
          <div class="bold">${this.getTakenDate()}</div>
        </div>
        <div class="line">
          <div>Dernière mise à jour :</div>
          <div class="bold">${this.parent.formatDate(this.ticket.lastUpdated)}</div>
        </div>
        <div class="line">
          <div>Date et heure de la clôture de l'incident :</div>
          <div class="bold">${this.parent.formatDate(this.ticket.resolved)}</div>
        </div>
        ${this.drawHistory()}
      </div>

      <modal-drawer label="Historique détaillé" id="modal-incident-history-view">
        <style type="text/css">${this.constructor.styles}</style>
        <div class="warning"><m-icon name="warning" nogradient></m-icon>En cours de développement</div>
        ${this.drawFullHistory()}
        <sl-button slot="bt1" variant="text" close="true">Fermer</sl-button>
      </modal-drawer>
    `;
  }

}

customElements.define('incident-block-horodatage', Block);